import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "EME Officers Mess, Delhi Cantonment",
  address: "Delhi Cantonment",
  client: "EME Officers Mess",
  area: "40000 Sq. Ft.",
  project:
    "Interior design for an army mess at Delhi Cantonment for their Function Halls, Bars, etc.",
  category: "Commercial | Interior",
  status: "Completed",
  backlink: "/projects/commercial/",
};

export default class Projectemeofficersmessdelhicantt extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Projects | EME Officers Mess, Delhi Cantonment"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the interior design project for an army mess at Delhi cantonment spanning over 40,000 sq. ft. with function halls and bar."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
